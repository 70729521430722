/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataPack
 */
export interface DataPack {
    /**
     * 
     * @type {string}
     * @memberof DataPack
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DataPack
     */
    productId: string;
    /**
     * 
     * @type {number}
     * @memberof DataPack
     */
    bytesRemaining: number;
    /**
     * 
     * @type {number}
     * @memberof DataPack
     */
    bytesTotal: number;
    /**
     * 
     * @type {number}
     * @memberof DataPack
     */
    bytesUsed: number;
}

/**
 * Check if a given object implements the DataPack interface.
 */
export function instanceOfDataPack(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('productId' in value)) return false;
    if (!('bytesRemaining' in value)) return false;
    if (!('bytesTotal' in value)) return false;
    if (!('bytesUsed' in value)) return false;
    return true;
}

export function DataPackFromJSON(json: any): DataPack {
    return DataPackFromJSONTyped(json, false);
}

export function DataPackFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataPack {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'productId': json['productId'],
        'bytesRemaining': json['bytesRemaining'],
        'bytesTotal': json['bytesTotal'],
        'bytesUsed': json['bytesUsed'],
    };
}

export function DataPackToJSON(value?: DataPack | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'productId': value['productId'],
        'bytesRemaining': value['bytesRemaining'],
        'bytesTotal': value['bytesTotal'],
        'bytesUsed': value['bytesUsed'],
    };
}

