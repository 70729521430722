/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateProxiesRequest,
  ErrorInfo,
  Proxy,
} from '../models/index';
import {
    CreateProxiesRequestFromJSON,
    CreateProxiesRequestToJSON,
    ErrorInfoFromJSON,
    ErrorInfoToJSON,
    ProxyFromJSON,
    ProxyToJSON,
} from '../models/index';

export interface ApiProxiesPostRequest {
    createProxiesRequest: Array<CreateProxiesRequest>;
}

export interface ApiProxiesProxyIdGetRequest {
    proxyId: string;
}

export interface ApiProxiesProxyIdReassignPostRequest {
    proxyId: string;
}

/**
 * 
 */
export class ProxiesApi extends runtime.BaseAPI {

    /**
     * Get my proxies
     */
    async apiProxiesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Proxy>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/proxies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProxyFromJSON));
    }

    /**
     * Get my proxies
     */
    async apiProxiesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Proxy>> {
        const response = await this.apiProxiesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * create proxy
     */
    async apiProxiesPostRaw(requestParameters: ApiProxiesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Proxy>> {
        if (requestParameters['createProxiesRequest'] == null) {
            throw new runtime.RequiredError(
                'createProxiesRequest',
                'Required parameter "createProxiesRequest" was null or undefined when calling apiProxiesPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/proxies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['createProxiesRequest']!.map(CreateProxiesRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProxyFromJSON(jsonValue));
    }

    /**
     * create proxy
     */
    async apiProxiesPost(requestParameters: ApiProxiesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Proxy> {
        const response = await this.apiProxiesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get my proxies
     */
    async apiProxiesProxyIdGetRaw(requestParameters: ApiProxiesProxyIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Proxy>> {
        if (requestParameters['proxyId'] == null) {
            throw new runtime.RequiredError(
                'proxyId',
                'Required parameter "proxyId" was null or undefined when calling apiProxiesProxyIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/proxies/{proxyId}`.replace(`{${"proxyId"}}`, encodeURIComponent(String(requestParameters['proxyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProxyFromJSON(jsonValue));
    }

    /**
     * Get my proxies
     */
    async apiProxiesProxyIdGet(requestParameters: ApiProxiesProxyIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Proxy> {
        const response = await this.apiProxiesProxyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reassign a proxy
     */
    async apiProxiesProxyIdReassignPostRaw(requestParameters: ApiProxiesProxyIdReassignPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Proxy>> {
        if (requestParameters['proxyId'] == null) {
            throw new runtime.RequiredError(
                'proxyId',
                'Required parameter "proxyId" was null or undefined when calling apiProxiesProxyIdReassignPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/proxies/{proxyId}/reassign`.replace(`{${"proxyId"}}`, encodeURIComponent(String(requestParameters['proxyId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProxyFromJSON(jsonValue));
    }

    /**
     * Reassign a proxy
     */
    async apiProxiesProxyIdReassignPost(requestParameters: ApiProxiesProxyIdReassignPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Proxy> {
        const response = await this.apiProxiesProxyIdReassignPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
