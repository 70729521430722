import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  useTheme,
  Button,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  FormControlLabel,
  CheckboxProps,
  IconButton,
  Divider,
  useMediaQuery,
  CardContent
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import proxiesService from '../../../services/ProxiesService';
import Checkbox from '@mui/material/Checkbox';
import {
  BpCheckedIcon,
  BpIcon,
  CountBadge,
  CreateButton,
  ExportButton,
  SelectAllButton,
  ToolbarContainer,
  StyledTable,
  MobileSelectAllButton,
  HoverableCard,
  StyledTableCell
} from './styles';
import CopyableField from './TableCopyObject';
import PasswordDialog from './PasswordDialog';
import { ThreeSixtySharp } from '@mui/icons-material';

function BpCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
        transition: 'all 0.2s ease-in-out'
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export const ProxyTable: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { proxies, loading, error, fetchProxies, reassignProxy } =
    proxiesService.useStore((state) => ({
      proxies: state.proxies,
      loading: state.loading,
      error: state.error,
      fetchProxies: state.fetchProxies,
      reassignProxy: state.reassignProxy
    }));

  const [selectedProxies, setSelectedProxies] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

  useEffect(() => {
    fetchProxies();
  }, [fetchProxies]);

  const handleSelectAllButton = () => {
    setSelectedProxies(
      selectedProxies.length === proxies.length ? [] : proxies.map((p) => p.id)
    );
  };

  const handleSelect = (id: string) => {
    setSelectedProxies((prev) =>
      prev.includes(id)
        ? prev.filter((proxyId) => proxyId !== id)
        : [...prev, id]
    );
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadCSV = (type: 'socks5' | 'http') => {
    const proxiesToExport =
      selectedProxies.length > 0
        ? selectedProxies
        : proxies.map((proxy) => proxy.id);
    proxiesService.exportProxies(proxiesToExport, type);
    handleMenuClose();
  };

  const handleCreateProxy = () => {
    proxiesService.createProxy();
  };

  const handleOpenPasswordDialog = () => {
    setPasswordDialogOpen(true);
  };

  const handlePasswordDialogClose = async (success: boolean) => {
    setPasswordDialogOpen(false);
    if (success) {
      await fetchProxies();
    }
  };

  const allSelected =
    proxies.length > 0 && selectedProxies.length === proxies.length;

  const splitAddress = (address: string) => {
    const [ip, port] = address.split(':');
    return { ip, port };
  };

  return (
    <Box sx={{ p: 2, maxWidth: '1800px', margin: '0 auto' }}>
      <ToolbarContainer sx={{ borderBottom: 'none' }}>
        <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
          <CountBadge>
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {proxies.length} total
            </Typography>
          </CountBadge>

          {!isMobile && (
            <FormControlLabel
              control={
                <BpCheckbox
                  checked={showAdvanced}
                  onChange={() => setShowAdvanced((prev) => !prev)}
                />
              }
              label="Show Advanced Info"
            />
          )}
        </Stack>

        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={1}
          justifyContent="flex-end"
          flexWrap="wrap"
          sx={{ width: isMobile ? '100%' : 'auto' }}
        >
          {isMobile && (
            <FormControlLabel
              control={
                <BpCheckbox
                  checked={showAdvanced}
                  onChange={() => setShowAdvanced((prev) => !prev)}
                />
              }
              label="Show Advanced Info"
              sx={{ alignSelf: 'flex-start' }}
            />
          )}
          <ExportButton
            variant="contained"
            color="primary"
            onClick={handleMenuOpen}
            startIcon={<DownloadIcon />}
            endIcon={<ArrowDropDownIcon />}
            disabled={loading || proxies.length === 0}
          >
            {selectedProxies.length > 0 ? 'Export Selected' : 'Export All'}
          </ExportButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleDownloadCSV('socks5')}>
              Export as SOCKS5
            </MenuItem>
            <MenuItem onClick={() => handleDownloadCSV('http')}>
              Export as HTTP
            </MenuItem>
          </Menu>

          <CreateButton
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={handleCreateProxy}
            disabled={loading}
          >
            Create Proxy
          </CreateButton>
        </Stack>
      </ToolbarContainer>

      <Paper
        sx={{
          borderRadius: isMobile ? '0px' : '0 0 12px 12px',
          overflow: 'hidden',
          borderTop: 'none',
          border: isMobile ? 'none' : '2px',
          boxShadow: isMobile ? 'none' : '1px',
          backgroundColor: 'unset'
        }}
      >
        {loading && (
          <Box sx={{ p: 4, textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        )}

        {error && !loading && (
          <Box sx={{ p: 4, textAlign: 'center' }}>
            <Typography color="error">{error}</Typography>
          </Box>
        )}

        {!loading && !error && proxies.length === 0 && (
          <Box sx={{ p: 4, textAlign: 'center' }}>
            <Typography color="text.secondary">No proxies found.</Typography>
          </Box>
        )}

        {!loading && !error && proxies.length > 0 && !isMobile && (
          <StyledTable stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <SelectAllButton
                    variant="text"
                    size="small"
                    onClick={handleSelectAllButton}
                  >
                    {selectedProxies.length === proxies.length
                      ? 'Deselect All'
                      : 'Select All'}
                  </SelectAllButton>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 0.5
                    }}
                  >
                    IP Address
                    <Tooltip
                      title={
                        <Box sx={{ maxWidth: 200 }}>
                          <Typography variant="body2" sx={{ mb: 0.5 }}>
                            This is your Gateway IP.
                          </Typography>
                          <Typography variant="body2">
                            It forwards traffic via the assigned proxy. Enable
                            "Show advanced info" to see actual IP of your
                            proxies.
                          </Typography>
                        </Box>
                      }
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        sx={{ opacity: 0.7 }}
                      />
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell>HTTP Port</TableCell>
                <TableCell>SOCKS5 Port</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>
                  Password
                  <IconButton onClick={handleOpenPasswordDialog}>
                    <SettingsIcon />
                  </IconButton>
                </TableCell>
                {showAdvanced && (
                  <>
                    <TableCell>Public IP</TableCell>
                    <TableCell>Network Name</TableCell>
                  </>
                )}
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {proxies.map((proxy) => {
                const { ip: socks5Ip, port: socks5Port } = splitAddress(
                  proxy.socks5Address
                );
                const { ip: _httpIp, port: httpPort } = splitAddress(
                  proxy.httpAddress
                );

                return (
                  <TableRow key={proxy.id}>
                    <TableCell>
                      <BpCheckbox
                        checked={selectedProxies.includes(proxy.id)}
                        onChange={() => handleSelect(proxy.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <CopyableField value={socks5Ip} />
                    </TableCell>
                    <TableCell>
                      <CopyableField value={httpPort} />
                    </TableCell>
                    <TableCell>
                      <CopyableField value={socks5Port} />
                    </TableCell>
                    <TableCell>
                      <CopyableField value={proxy.username} />
                    </TableCell>
                    <TableCell>
                      <CopyableField value={proxy.password} />
                    </TableCell>
                    {showAdvanced && (
                      <>
                        <TableCell>
                          <Typography variant="body2" noWrap>
                            {proxy.publicIp}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" noWrap>
                            {proxy.networkName}
                          </Typography>
                        </TableCell>
                      </>
                    )}
                    <StyledTableCell align="left">
                      {proxy.status === 'Online' ? (
                        <CheckCircleOutlineIcon color="success" />
                      ) : (
                        <HighlightOffIcon color="error" />
                      )}
                    </StyledTableCell>
                    <TableCell>
                      <Tooltip title="Rotate proxy">
                        <IconButton onClick={() => reassignProxy(proxy.id)}>
                          <ThreeSixtySharp />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        )}

        {!loading && !error && proxies.length > 0 && isMobile && (
          <>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-start' }}>
              <MobileSelectAllButton
                variant="outlined"
                onClick={handleSelectAllButton}
                disabled={proxies.length === 0}
              >
                {allSelected ? 'Deselect All' : 'Select All'}
              </MobileSelectAllButton>
            </Box>

            <Stack spacing={2}>
              {proxies.map((proxy) => {
                const { ip: socks5Ip, port: socks5Port } = splitAddress(
                  proxy.socks5Address
                );
                const { ip: _httpIp, port: httpPort } = splitAddress(
                  proxy.httpAddress
                );

                return (
                  <HoverableCard key={proxy.id}>
                    <CardContent
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        transition: 'all 0.2s ease-in-out'
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <BpCheckbox
                          checked={selectedProxies.includes(proxy.id)}
                          onChange={() => handleSelect(proxy.id)}
                        />
                        <Box sx={{ textAlign: 'center' }}>
                          {proxy.status === 'Online' ? (
                            <CheckCircleOutlineIcon color="success" />
                          ) : (
                            <HighlightOffIcon color="error" />
                          )}
                        </Box>
                      </Box>
                      <Divider
                        sx={{ transition: 'background-color 0.2s ease-in-out' }}
                      />

                      <Stack
                        spacing={1}
                        sx={{ transition: 'all 0.2s ease-in-out' }}
                      >
                        <Box
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: 0.5
                          }}
                        >
                          <Typography variant="body2" color="text.secondary">
                            IP Address
                          </Typography>
                          <Tooltip
                            title={
                              <Box sx={{ maxWidth: 200 }}>
                                <Typography variant="body2" sx={{ mb: 0.5 }}>
                                  This is your Gateway IP.
                                </Typography>
                                <Typography variant="body2">
                                  It forwards traffic via the assigned proxy.
                                  Enable "Show advanced info" to see actual IP
                                  of your proxies.
                                </Typography>
                              </Box>
                            }
                            arrow
                          >
                            <InfoOutlinedIcon
                              fontSize="small"
                              sx={{ opacity: 0.7 }}
                            />
                          </Tooltip>
                        </Box>
                        <CopyableField value={socks5Ip} />

                        <Typography variant="body2" color="text.secondary">
                          HTTP Port
                        </Typography>
                        <CopyableField value={httpPort} />

                        <Typography variant="body2" color="text.secondary">
                          SOCKS5 Port
                        </Typography>
                        <CopyableField value={socks5Port} />

                        <Typography variant="body2" color="text.secondary">
                          Username
                        </Typography>
                        <CopyableField value={proxy.username} />

                        <Typography variant="body2" color="text.secondary">
                          Password
                        </Typography>
                        <CopyableField value={proxy.password} />

                        {showAdvanced && (
                          <>
                            <Typography variant="body2" color="text.secondary">
                              Public IP
                            </Typography>
                            <Typography variant="body1" noWrap>
                              {proxy.publicIp}
                            </Typography>

                            <Typography variant="body2" color="text.secondary">
                              Network Name
                            </Typography>
                            <Typography variant="body1" noWrap>
                              {proxy.networkName}
                            </Typography>
                          </>
                        )}
                        <Tooltip title="Rotate proxy">
                          <IconButton onClick={() => reassignProxy(proxy.id)}>
                            <Typography
                              variant="subtitle1"
                              sx={{ paddingRight: '10px' }}
                            >
                              Rotate proxy
                            </Typography>
                            <ThreeSixtySharp />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </CardContent>
                  </HoverableCard>
                );
              })}
            </Stack>
          </>
        )}
      </Paper>

      <PasswordDialog
        open={passwordDialogOpen}
        handleClose={handlePasswordDialogClose}
      />
    </Box>
  );
};
