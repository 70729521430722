import { useState, useContext } from 'react';
import {
  alpha,
  Box,
  Button,
  List,
  ListItem,
  ListSubheader,
  Collapse,
  styled
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  ExpandLess,
  ExpandMore,
  ListAltOutlined,
  DataUsageTwoTone,
  Inventory,
  DeviceHub,
  NetworkCheckOutlined,
  Subscriptions,
  ViewListOutlined,
  ShareOutlined,
  QuestionMarkOutlined
} from '@mui/icons-material';
import { SidebarContext } from 'src/contexts/SidebarContext';
import accountService from 'src/services/AccountService';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};
  }

  .MuiListSubheader-root {
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(14)};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: ${theme.spacing(1.5, 2)};
    line-height: 1.2;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s, color 0.2s;
    background-color: rgba(0, 0, 0, 0);

    &:hover {
      border-radius: 10px;
      color: ${theme.colors.alpha.trueWhite[100]};
    }

    &.active {
      background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.02)};
      border-radius: 10px;
      color: ${theme.colors.alpha.trueWhite[100]};
    }
  }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiButton-root {
    color: ${theme.colors.alpha.trueWhite[70]};
    justify-content: flex-start;
    padding: ${theme.spacing(1, 2)};
    width: 100%;
    font-size: ${theme.typography.pxToRem(15)};
    font-weight: 500;

    &.active,
    &:hover {
      background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.08)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
  }

  .MuiListItem-root {
    margin-bottom: ${theme.spacing(1)};
    padding: 0;
  }

  .MuiListItem-root:last-child {
    margin-bottom: 0;
  }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const { account } = accountService.useStore((state) => state);

  const [openMenu, setOpenMenu] = useState({
    dashboards: true,
    admin: true,
    management: true
  });

  const handleToggle = (menu) => {
    setOpenMenu((prev) => ({ ...prev, [menu]: !prev[menu] }));
  };

  return (
    <MenuWrapper>
      {/* Home  */}
      <List component="nav">
        <ListSubheader
          onClick={() => handleToggle('dashboards')}
          className={openMenu['dashboards'] ? 'active' : ''}
        >
          Home
          {openMenu['dashboards'] ? (
            <ExpandLess fontSize="large" />
          ) : (
            <ExpandMore fontSize="large" />
          )}
        </ListSubheader>
        <Collapse in={openMenu['dashboards']} timeout="auto" unmountOnExit>
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  component={RouterLink}
                  to="/dashboards/proxies"
                  startIcon={<ListAltOutlined />}
                  onClick={closeSidebar}
                >
                  Proxies
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  component={RouterLink}
                  to="/dashboards/datausage"
                  startIcon={<DataUsageTwoTone />}
                  onClick={closeSidebar}
                >
                  Data Usage
                </Button>
              </ListItem>
              {account?.plan ? (
                <ListItem component="div">
                  <Button
                    component={RouterLink}
                    to="/management/plans"
                    startIcon={<ViewListOutlined />}
                    onClick={closeSidebar}
                  >
                    Manage Subscription
                  </Button>
                </ListItem>
              ) : (
                <ListItem component="div">
                  <Button
                    component={RouterLink}
                    to="/management/plans"
                    startIcon={<ViewListOutlined />}
                    onClick={closeSidebar}
                  >
                    Buy
                  </Button>
                </ListItem>
              )}
            </List>
          </SubMenuWrapper>
        </Collapse>
      </List>

      {/* Admin Section */}
      {(account?.role === 'Admin' ||
        account?.email === 'michaltutak12+3@gmail.com') && (
        <List component="nav">
          <ListSubheader
            onClick={() => handleToggle('admin')}
            className={openMenu['admin'] ? 'active' : ''}
          >
            Admin
            {openMenu['admin'] ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </ListSubheader>
          <Collapse in={openMenu['admin']} timeout="auto" unmountOnExit>
            <SubMenuWrapper>
              <List component="div" disablePadding>
                <ListItem component="div">
                  <Button
                    component={RouterLink}
                    to="/admin/products"
                    startIcon={<Inventory />}
                    onClick={closeSidebar}
                  >
                    Products
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    component={RouterLink}
                    to="/admin/plans"
                    startIcon={<Subscriptions />}
                    onClick={closeSidebar}
                  >
                    Plans
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    component={RouterLink}
                    to="/admin/devices"
                    startIcon={<DeviceHub />}
                    onClick={closeSidebar}
                  >
                    Devices
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    component={RouterLink}
                    to="/admin/proxies"
                    startIcon={<NetworkCheckOutlined />}
                    onClick={closeSidebar}
                  >
                    Proxies
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </Collapse>
        </List>
      )}

      {/* Management Section */}
      <List component="nav">
        <ListSubheader
          onClick={() => handleToggle('management')}
          className={openMenu['management'] ? 'active' : ''}
        >
          Management
          {openMenu['management'] ? (
            <ExpandLess fontSize="large" />
          ) : (
            <ExpandMore fontSize="large" />
          )}
        </ListSubheader>
        <Collapse in={openMenu['management']} timeout="auto" unmountOnExit>
          <SubMenuWrapper>
            <List component="div" disablePadding>
              {account?.affiliateCode && (
                <ListItem component="div">
                  <Button
                    component={RouterLink}
                    to="/management/affiliate"
                    startIcon={<ShareOutlined />}
                    onClick={closeSidebar}
                  >
                    Affiliate
                  </Button>
                </ListItem>
              )}
              <ListItem component="div">
                <Button
                  component={RouterLink}
                  to="/management/faq"
                  startIcon={<QuestionMarkOutlined />}
                  onClick={closeSidebar}
                >
                  FAQ
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </Collapse>
      </List>
    </MenuWrapper>
  );
}

export default SidebarMenu;
