import {
  ApiProxiesPostRequest,
  ApiProxiesProxyIdGetRequest,
  ApiProxiesProxyIdReassignPostRequest,
  ProxiesApi
} from 'src/api/apis/ProxiesApi';
import { Proxy } from 'src/api/models/Proxy';
import { LoadingState } from '../util/LoadingState';
import { create } from 'zustand';
import { apiConfig } from '../api';
import { ErrorState } from '../util/ErrorState';

interface ProxiesState extends LoadingState, ErrorState {
  proxies: Proxy[];
  fetchProxies: () => Promise<Proxy[]>;
  fetchProxyById: (proxyId: string) => Promise<Proxy>;
  createProxy: () => Promise<Proxy>;
  reassignProxy: (proxyId: string) => Promise<Proxy>;
}

const useProxiesStore = create<ProxiesState>((set) => ({
  proxies: [],
  loading: false,
  error: false,
  fetchProxies: async () => {
    set({ loading: true });
    try {
      const proxiesApi = new ProxiesApi(apiConfig);
      const proxies = await proxiesApi.apiProxiesGet();
      set({ proxies, loading: false });
      return proxies;
    } catch (e) {
      console.error('Failed to fetch proxies:', e);
      set({ loading: false });
      throw e;
    }
  },
  reassignProxy: async (proxyId: string) => {
    set({ loading: true });
    try {
      const request: ApiProxiesProxyIdReassignPostRequest = { proxyId };
      const proxiesApi = new ProxiesApi(apiConfig);
      const proxy = await proxiesApi.apiProxiesProxyIdReassignPost(request);
      const proxies = await useProxiesStore.getState().fetchProxies();
      set({ proxies, loading: false });
      return proxy;
    } catch (e) {
      console.error('Failed to reassign proxy:', e);
      set({ loading: false });
      throw e;
    }
  },
  fetchProxyById: async (proxyId: string) => {
    try {
      const request: ApiProxiesProxyIdGetRequest = { proxyId };
      const proxiesApi = new ProxiesApi(apiConfig);
      const proxy = await proxiesApi.apiProxiesProxyIdGet(request);
      return proxy;
    } catch (e) {
      console.error('Failed to fetch proxy by ID:', e);
      throw e;
    }
  },
  createProxy: async () => {
    set({ loading: true });
    try {
      const request: ApiProxiesPostRequest = {
        createProxiesRequest: [{ location: 'UK', amount: 1 }]
      };
      const proxiesApi = new ProxiesApi(apiConfig);
      const proxy = await proxiesApi.apiProxiesPost(request);
      await useProxiesStore.getState().fetchProxies();
      set({ loading: false });
      return proxy;
    } catch (e) {
      console.error('Failed to create proxy:', e);
      set({ loading: false });
      throw e;
    }
  }
}));

class ProxiesService {
  useStore = useProxiesStore;
  fetchProxies = async () => this.useStore.getState().fetchProxies();
  fetchProxyById = async (proxyId: string) =>
    this.useStore.getState().fetchProxyById(proxyId);
  createProxy = async () => this.useStore.getState().createProxy();
  reassignProxy = async (proxyId: string) =>
    this.useStore.getState().reassignProxy(proxyId);

  exportProxies(selectedProxies: string[], type: 'socks5' | 'http') {
    const state = this.useStore.getState();
    const proxiesToExport = state.proxies.filter((proxy) =>
      selectedProxies.includes(proxy.id)
    );
    const csvContent = proxiesToExport
      .map((proxy) => {
        return `${type === 'socks5' ? `${proxy.socks5Address}:${proxy.username}:${proxy.password}` : `${proxy.httpAddress}:${proxy.username}:${proxy.password}`}`;
      })
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `proxies_${type}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

const proxiesService = new ProxiesService();
export default proxiesService;
