/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Carrier } from './Carrier';
import {
    CarrierFromJSON,
    CarrierFromJSONTyped,
    CarrierToJSON,
} from './Carrier';

/**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    description?: string;
    /**
     * 
     * @type {Array<Carrier>}
     * @memberof Plan
     */
    carriers: Array<Carrier>;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    stripeId: string;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    allowedProxyCount: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    monthlyCostCents: number;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    maxSpeedKbs: number;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    stripePriceId: string;
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    initialFreeDataMB: number;
    /**
     * 
     * @type {boolean}
     * @memberof Plan
     */
    active: boolean;
}

/**
 * Check if a given object implements the Plan interface.
 */
export function instanceOfPlan(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('carriers' in value)) return false;
    if (!('stripeId' in value)) return false;
    if (!('allowedProxyCount' in value)) return false;
    if (!('monthlyCostCents' in value)) return false;
    if (!('maxSpeedKbs' in value)) return false;
    if (!('stripePriceId' in value)) return false;
    if (!('initialFreeDataMB' in value)) return false;
    if (!('active' in value)) return false;
    return true;
}

export function PlanFromJSON(json: any): Plan {
    return PlanFromJSONTyped(json, false);
}

export function PlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): Plan {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'carriers': ((json['carriers'] as Array<any>).map(CarrierFromJSON)),
        'stripeId': json['stripeId'],
        'allowedProxyCount': json['allowedProxyCount'],
        'monthlyCostCents': json['monthlyCostCents'],
        'maxSpeedKbs': json['maxSpeedKbs'],
        'stripePriceId': json['stripePriceId'],
        'initialFreeDataMB': json['initialFreeDataMB'],
        'active': json['active'],
    };
}

export function PlanToJSON(value?: Plan | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'carriers': ((value['carriers'] as Array<any>).map(CarrierToJSON)),
        'stripeId': value['stripeId'],
        'allowedProxyCount': value['allowedProxyCount'],
        'monthlyCostCents': value['monthlyCostCents'],
        'maxSpeedKbs': value['maxSpeedKbs'],
        'stripePriceId': value['stripePriceId'],
        'initialFreeDataMB': value['initialFreeDataMB'],
        'active': value['active'],
    };
}

