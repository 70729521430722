import React, { useState } from 'react';
import { Alert, Box, Snackbar, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyableContainer, CopyIconContainer } from './styles';

type CopyFieldProps = {
  value: string;
};

const CopyableField: React.FC<CopyFieldProps> = ({ value }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  return (
    <CopyableContainer onClick={handleCopy}>
      <Typography
        variant="body2"
        noWrap
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', flexShrink: 1 }}
      >
        {value}
      </Typography>
      <Tooltip title="Copy" arrow>
        <CopyIconContainer>
          <ContentCopyIcon fontSize="small" />
        </CopyIconContainer>
      </Tooltip>
      <Snackbar
        open={copied}
        autoHideDuration={1500}
        onClose={() => setCopied(false)}
      >
        <Alert
          onClose={() => setCopied(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          Copied!
        </Alert>
      </Snackbar>
    </CopyableContainer>
  );
};

export default CopyableField;
