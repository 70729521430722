import { useMemo } from 'react';
import {
  Box,
  LinearProgress,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import accountService from 'src/services/AccountService';

const shortenBytes = (bytes: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let i = 0;
  while (bytes >= 1024 && i < units.length - 1) {
    bytes /= 1024;
    i++;
  }
  return `${bytes.toFixed(2)} ${units[i]}`;
};

const DataPackIndicatorWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(2, 0)
}));

const DataPackProgress = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[300],
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5
  }
}));

const DataPackIndicator = () => {
  const theme = useTheme();
  const { account, loading, getCount } = accountService.useStore(
    (state) => state
  );

  const totalRemaining = useMemo(
    () => (account ? accountService.getTotalDataRemaining() : 0),
    [account]
  );

  const totalAllocated = useMemo(
    () => (account ? accountService.getTotalDataAllocated() : 0),
    [account]
  );

  if (getCount <= 0 || loading) return null;

  const progress = (totalRemaining / totalAllocated) * 100;

  if (totalRemaining <= 0) {
    return <DataPackIndicatorWrapper />;
  }

  return (
    <DataPackIndicatorWrapper>
      <Typography variant="h6" sx={{ color: theme.palette.info.dark }}>
        Data Remaining
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: theme.palette.info.contrastText }}
      >
        {shortenBytes(totalRemaining)}{' '}
        <span style={{ color: theme.palette.grey[500] }}>out of</span>{' '}
        {shortenBytes(totalAllocated)}
      </Typography>
      <DataPackProgress variant="determinate" value={Math.min(progress, 100)} />
    </DataPackIndicatorWrapper>
  );
};

export default DataPackIndicator;
