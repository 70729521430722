import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CheckboxProps,
  IconButton,
  Table,
  TableCell
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';

export const StyledBox = styled(Box)({
  width: '100%',
  overflowX: 'auto'
});

export const StyledTableCell = styled(TableCell)({
  paddingLeft: 24
});

export const StyledIconButton = styled(IconButton)({
  marginLeft: 5
});

export const CopyableContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  cursor: 'pointer',
  minWidth: 0,
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius / 2,
  maxWidth: '100%',
  transition: 'background-color 0.2s ease-in-out',
  '&:hover': {}
}));

export const CopyIconContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  opacity: 0.6,
  transition: 'opacity 0.2s ease-in-out',
  '&:hover': {
    opacity: 1
  }
}));

export const ToolbarContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  backgroundColor: alpha(theme.palette.action.hover, 0.3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  transition: 'background-color 0.2s ease-in-out',

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {
    flexDirection: 'column',
    textAlign: 'center'
  }
}));

export const CountBadge = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.action.selected, 0.4),
  borderRadius: '9999px',
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  display: 'inline-flex',
  alignItems: 'center',
  transition: 'background-color 0.2s ease-in-out'
}));

export const StyledTable = styled(Table)(({ theme }) => ({
  borderCollapse: 'collapse',
  backgroundColor: theme.palette.background.default,
  transition: 'background-color 0.2s ease-in-out',
  '& thead th': {
    fontWeight: 'unset',
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
    textTransform: 'uppercase',
    fontSize: '0.85rem',
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 2),
    whiteSpace: 'nowrap',
    textAlign: 'left'
  },
  '& tbody td': {
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
    textAlign: 'left',
    transition: 'background-color 0.2s ease-in-out'
  },
  '& tbody tr:hover': {
    backgroundColor: alpha(theme.palette.action.hover, 0.3)
  }
}));

export const SelectAllButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  minWidth: '90px',
  padding: '0 4px',
  color: theme.palette.text.primary,
  textAlign: 'left',
  justifyContent: 'flex-start',
  transition: 'background-color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: alpha(theme.palette.action.hover, 0.1)
  }
}));

export const ExportButton = styled(Button)(({ theme }) => ({
  transition: 'background-color 0.2s ease-in-out',
  fontWeight: 'unset',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.7)
  }
}));

export const CreateButton = styled(Button)(({ theme }) => ({
  transition: 'background-color 0.2s ease-in-out',
  fontWeight: 'unset',
  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.7)
  }
}));

export const MobileSelectAllButton = styled(Button)(({ theme }) => ({
  minWidth: '90px',
  textAlign: 'left',
  justifyContent: 'flex-start',
  transition: 'background-color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: alpha(theme.palette.action.hover, 0.1)
  }
}));

export const HoverableCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'none',
  backgroundColor: theme.palette.background.paper,
  transition: 'background-color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: alpha(theme.palette.action.hover, 0.3)
  }
}));

export const BpIcon = styled('span')(({ theme }) => {
  const isDark = theme.palette.mode === 'dark';
  return {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: isDark
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: isDark ? '#394b59' : '#f5f8fa',
    backgroundImage: isDark
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: isDark ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)'
    }
  };
});

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    content: '""',
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")"
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3'
  }
});
