import React, { useEffect, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import proxiesService from '../../../services/ProxiesService';
import StatusTiles from './StatusTiles';
import { HeaderTitleContext } from '../../../contexts/HeaderTitleContext';
import { ProxyTable } from './ProxyTable';

const Proxies: React.FC = () => {
  const { proxies, error, loading } = proxiesService.useStore((state) => state);
  const { setTitle } = useContext(HeaderTitleContext);

  useEffect(() => {
    setTitle(
      <>
        <Typography
          variant="h3"
          sx={{ paddingLeft: '10px', whiteSpace: 'nowrap' }}
        >
          Proxies
        </Typography>
      </>
    );
  }, [proxies.length, setTitle]);

  if (error)
    return <Typography color="error">Error loading data: {error}</Typography>;

  return (
    <Box sx={{ p: 3 }}>
      <ProxyTable />
      <Box
        sx={{
          mt: 5,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <StatusTiles />
      </Box>
    </Box>
  );
};

export default Proxies;
