import React, { useContext, useEffect, useState } from 'react';
import { Switch, useTheme, Box, styled, Typography, Grid } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { ThemeContext } from '../../theme/ThemeProvider';

const AnimatedSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 5,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        opacity: 1,
        border: 0
      }
    }
  },
  '& .MuiSwitch-thumb': {
    width: 22,
    height: 22,
    backgroundColor: theme.palette.mode === 'dark' ? '#ffb74d' : '#4dabf5'
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
    backgroundColor: theme.palette.mode === 'dark' ? '#aab4be' : '#8796A5',
    opacity: 1,
    transition: 'background-color 300ms'
  }
}));

const ThemeToggleSwitch: React.FC = () => {
  const theme = useTheme();
  const setThemeName = useContext(ThemeContext);
  const [isDarkMode, setIsDarkMode] = useState(theme.palette.mode === 'dark');

  const toggleTheme = () => {
    if (isDarkMode) {
      setThemeName('PureLightTheme');
    } else {
      setThemeName('NebulaFighterTheme');
    }
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    setIsDarkMode(theme.palette.mode === 'dark');
  }, [theme.palette.mode]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Typography variant="body1" sx={{ mr: 2 }}>
        Toggle theme
      </Typography>
      <Box display="flex" alignItems="center">
        <Brightness7
          sx={{ mr: 1, color: isDarkMode ? 'grey.500' : 'orange' }}
        />
        <AnimatedSwitch checked={isDarkMode} onChange={toggleTheme} />
        <Brightness4
          sx={{ ml: 1, color: isDarkMode ? '#ffb74d' : 'grey.500' }}
        />
      </Box>
    </Box>
  );
};

export default ThemeToggleSwitch;
