/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AffiliatePayout
 */
export interface AffiliatePayout {
    /**
     * 
     * @type {string}
     * @memberof AffiliatePayout
     */
    id: string;
    /**
     *     
     * @type {boolean}
     * @memberof AffiliatePayout
     */
    paid: boolean;
    /**
     * 
     * @type {number}
     * @memberof AffiliatePayout
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliatePayout
     */
    amount: number;
}

/**
 * Check if a given object implements the AffiliatePayout interface.
 */
export function instanceOfAffiliatePayout(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('paid' in value)) return false;
    if (!('created' in value)) return false;
    if (!('amount' in value)) return false;
    return true;
}

export function AffiliatePayoutFromJSON(json: any): AffiliatePayout {
    return AffiliatePayoutFromJSONTyped(json, false);
}

export function AffiliatePayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): AffiliatePayout {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'paid': json['paid'],
        'created': json['created'],
        'amount': json['amount'],
    };
}

export function AffiliatePayoutToJSON(value?: AffiliatePayout | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'paid': value['paid'],
        'created': value['created'],
        'amount': value['amount'],
    };
}

