/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckoutResponse
 */
export interface CheckoutResponse {
    /**
     * 
     * @type {string}
     * @memberof CheckoutResponse
     */
    key: string;
}

/**
 * Check if a given object implements the CheckoutResponse interface.
 */
export function instanceOfCheckoutResponse(value: object): boolean {
    if (!('key' in value)) return false;
    return true;
}

export function CheckoutResponseFromJSON(json: any): CheckoutResponse {
    return CheckoutResponseFromJSONTyped(json, false);
}

export function CheckoutResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckoutResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'],
    };
}

export function CheckoutResponseToJSON(value?: CheckoutResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
    };
}

