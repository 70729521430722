import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Button,
  Box
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import productsService from '../../../services/ProductsService';
import plansService from '../../../services/PlansService';
import accountService from '../../../services/AccountService';
import { Product } from 'src/api/models/Product';
import { Plan } from 'src/api/models/Plan';
import ProductTile from './ProductTile';
import Basket, { BasketItem } from './Basket';
import { StyledContainer, StyledBox, StyledSideBox } from './styles';
import CheckoutModal from './CheckoutModal';
import { auth } from '../../../firebase-auth';

const Subscriptions = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [basket, setBasket] = useState<Map<string, BasketItem>>(new Map());
  const [existingSubscription, setExistingSubscription] = useState<
    Plan | undefined
  >();
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);

  useEffect(() => {
    const fetchProductsAndPlans = async () => {
      setLoading(true);
      try {
        const fetchedProducts = await productsService.getProducts();
        const fetchedPlans = await plansService.getPlans();
        setProducts(fetchedProducts.filter((product) => product.active));
        const account = await accountService.getAccountData();
        console.log(account);
        if (account && account.plan) {
          console.log(account.plan);
          console.log('testtt');
          setExistingSubscription(account.plan);
        } else {
          setPlans(fetchedPlans.filter((plan) => plan.active));
        }
      } catch (error) {
        console.error('Failed to fetch products and plans:', error);
        setError('Failed to load data. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    fetchProductsAndPlans();
  }, []);

  const handleAddPlanItem = useCallback((plan: Plan) => {
    setBasket((prev) => {
      const newBasket = new Map(prev);
      Array.from(newBasket.values()).forEach((item) => {
        if (item.plan) {
          newBasket.delete(item.plan.id);
        }
      });
      newBasket.set(plan.id, { plan, quantity: 1 });
      return newBasket;
    });
    setError('');
  }, []);

  const handleAddProductItem = useCallback((product: Product) => {
    setBasket((prev) => {
      const newBasket = new Map(prev);
      const existingEntry = newBasket.get(product.id);
      const newQuantity = existingEntry ? existingEntry.quantity + 1 : 1;
      newBasket.set(product.id, { product, quantity: newQuantity });
      return newBasket;
    });
    setError('');
  }, []);

  return (
    <StyledContainer>
      <StyledBox>
        <Collapse in={!!error}>
          <Alert
            severity="error"
            action={
              <IconButton size="small" onClick={() => setError('')}>
                <CloseOutlined fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {error}
          </Alert>
        </Collapse>

        {existingSubscription ? (
          <>
            <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Box>
                  <Typography variant="h5" gutterBottom>
                    Your Current Subscription Plan
                  </Typography>
                  <Typography variant="subtitle1">
                    {existingSubscription.name}
                  </Typography>
                  <Typography variant="body2">
                    Monthly Cost: $
                    {(existingSubscription.monthlyCostCents / 100).toFixed(2)}
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    const url = `https://billing.stripe.com/p/login/aEU6s38Dw3TB4Hm000?prefilled_email=${auth.currentUser?.email}`;
                    const newTab = window.open(url, '_blank');

                    if (
                      !newTab ||
                      newTab.closed ||
                      typeof newTab.closed === 'undefined'
                    ) {
                      window.location.href = url;
                    }
                  }}
                >
                  Manage payments and upgrades
                </Button>
              </Box>
            </Paper>
            <Typography variant="h5" gutterBottom fontWeight="medium">
              Available Data Packs
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Choose from a range of data packs to enhance your current
              subscription.
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={3}>
              {loading ? (
                <CircularProgress />
              ) : (
                products.map((product) => (
                  <Grid item xs={12} sm={6} md={4} key={product.id}>
                    <ProductTile
                      product={{
                        ...product,
                        freeData: ''
                      }}
                      addToBasket={() => handleAddProductItem(product)}
                      isSubscriptionInBasket={false}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </>
        ) : (
          <>
            <Typography variant="h3" gutterBottom fontWeight="bold">
              Step 1 - Choose a Subscription Plan
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Select a subscription plan that best fits your needs. You may only
              have one active plan at a time.
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={3}>
              {loading ? (
                <CircularProgress />
              ) : (
                plans.map((plan) => (
                  <Grid item xs={12} sm={6} md={4} key={plan.id}>
                    <ProductTile
                      product={{
                        name: plan.name,
                        costCents: plan.monthlyCostCents,
                        subscription: true,
                        stripePriceId: plan.stripePriceId,
                        id: plan.id.toString(),
                        freeData: `${plan.initialFreeDataMB || 0}MB`
                      }}
                      addToBasket={() => handleAddPlanItem(plan)}
                      isSubscriptionInBasket={Array.from(basket.values()).some(
                        (item) => item.plan
                      )}
                    />
                  </Grid>
                ))
              )}
            </Grid>

            <Divider sx={{ my: 4 }} />

            <Typography display="inline" variant="h3" gutterBottom>
              Step 2 - Add Data Packs
            </Typography>
            <Typography display="inline" variant="h4">
              {' '}
              (optional)
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Make sure you have enough data for your use case.
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              These do not expire, are shared across all of your assigned
              proxies, and can be bought at any time!
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={3}>
              {loading ? (
                <CircularProgress />
              ) : (
                products.map((product) => (
                  <Grid item xs={12} sm={6} md={4} key={product.id}>
                    <ProductTile
                      product={{
                        ...product,
                        freeData: ''
                      }}
                      addToBasket={() => handleAddProductItem(product)}
                      isSubscriptionInBasket={false}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </>
        )}
      </StyledBox>

      <StyledSideBox>
        <Basket
          basket={basket}
          onCheckout={() => setShowCheckoutModal(true)}
          updateBasket={setBasket}
        />
      </StyledSideBox>
      <CheckoutModal
        open={showCheckoutModal}
        onClose={() => setShowCheckoutModal(false)}
        basket={basket}
        agreeToTerms={false}
        setAgreeToTerms={() => {}}
        addDataPackToBasket={() => {}}
        products={products}
      />
    </StyledContainer>
  );
};

export default Subscriptions;
