/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProxyStatus = {
    Offline: 'Offline',
    Online: 'Online',
    NoConnection: 'NoConnection'
} as const;
export type ProxyStatus = typeof ProxyStatus[keyof typeof ProxyStatus];


export function instanceOfProxyStatus(value: any): boolean {
    return Object.values(ProxyStatus).includes(value);
}

export function ProxyStatusFromJSON(json: any): ProxyStatus {
    return ProxyStatusFromJSONTyped(json, false);
}

export function ProxyStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProxyStatus {
    return json as ProxyStatus;
}

export function ProxyStatusToJSON(value?: ProxyStatus | null): any {
    return value as any;
}

