/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AccountRole = {
    Admin: 'Admin',
    User: 'User'
} as const;
export type AccountRole = typeof AccountRole[keyof typeof AccountRole];


export function instanceOfAccountRole(value: any): boolean {
    return Object.values(AccountRole).includes(value);
}

export function AccountRoleFromJSON(json: any): AccountRole {
    return AccountRoleFromJSONTyped(json, false);
}

export function AccountRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountRole {
    return json as AccountRole;
}

export function AccountRoleToJSON(value?: AccountRole | null): any {
    return value as any;
}

