/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProxyStatus } from './ProxyStatus';
import {
    ProxyStatusFromJSON,
    ProxyStatusFromJSONTyped,
    ProxyStatusToJSON,
} from './ProxyStatus';

/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    publicIp?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    privateIp: string;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    signalStrength?: number;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    networkName?: string;
    /**
     * 
     * @type {ProxyStatus}
     * @memberof Device
     */
    status: ProxyStatus;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Device
     */
    lastOnlineAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    uploadSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    downloadSpeed?: number;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    nodeId: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    nodeIp: string;
}

/**
 * Check if a given object implements the Device interface.
 */
export function instanceOfDevice(value: object): boolean {
    if (!('privateIp' in value)) return false;
    if (!('status' in value)) return false;
    if (!('id' in value)) return false;
    if (!('nodeId' in value)) return false;
    if (!('nodeIp' in value)) return false;
    return true;
}

export function DeviceFromJSON(json: any): Device {
    return DeviceFromJSONTyped(json, false);
}

export function DeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Device {
    if (json == null) {
        return json;
    }
    return {
        
        'publicIp': json['publicIp'] == null ? undefined : json['publicIp'],
        'privateIp': json['privateIp'],
        'signalStrength': json['signalStrength'] == null ? undefined : json['signalStrength'],
        'networkName': json['networkName'] == null ? undefined : json['networkName'],
        'status': ProxyStatusFromJSON(json['status']),
        'id': json['id'],
        'lastOnlineAt': json['lastOnlineAt'] == null ? undefined : (new Date(json['lastOnlineAt'])),
        'uploadSpeed': json['uploadSpeed'] == null ? undefined : json['uploadSpeed'],
        'downloadSpeed': json['downloadSpeed'] == null ? undefined : json['downloadSpeed'],
        'nodeId': json['nodeId'],
        'nodeIp': json['nodeIp'],
    };
}

export function DeviceToJSON(value?: Device | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'publicIp': value['publicIp'],
        'privateIp': value['privateIp'],
        'signalStrength': value['signalStrength'],
        'networkName': value['networkName'],
        'status': ProxyStatusToJSON(value['status']),
        'id': value['id'],
        'lastOnlineAt': value['lastOnlineAt'] == null ? undefined : ((value['lastOnlineAt']).toISOString()),
        'uploadSpeed': value['uploadSpeed'],
        'downloadSpeed': value['downloadSpeed'],
        'nodeId': value['nodeId'],
        'nodeIp': value['nodeIp'],
    };
}

