import React, { createContext, useState, ReactNode } from 'react';

interface HeaderTitleContextProps {
  title: ReactNode;
  setTitle: (newTitle: ReactNode) => void;
}

export const HeaderTitleContext = createContext<HeaderTitleContextProps>({
  title: null,
  setTitle: () => {}
});

export const HeaderTitleProvider: React.FC = ({ children }) => {
  const [title, setTitle] = useState<ReactNode>(null);

  return (
    <HeaderTitleContext.Provider value={{ title, setTitle }}>
      {children}
    </HeaderTitleContext.Provider>
  );
};
