/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProxyStatus } from './ProxyStatus';
import {
    ProxyStatusFromJSON,
    ProxyStatusFromJSONTyped,
    ProxyStatusToJSON,
} from './ProxyStatus';

/**
 * 
 * @export
 * @interface Proxy
 */
export interface Proxy {
    /**
     * 
     * @type {string}
     * @memberof Proxy
     */
    publicIp: string;
    /**
     * 
     * @type {number}
     * @memberof Proxy
     */
    signalStrength: number;
    /**
     * 
     * @type {string}
     * @memberof Proxy
     */
    networkName: string;
    /**
     * 
     * @type {ProxyStatus}
     * @memberof Proxy
     */
    status: ProxyStatus;
    /**
     * 
     * @type {string}
     * @memberof Proxy
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof Proxy
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof Proxy
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Proxy
     */
    socks5Address: string;
    /**
     * 
     * @type {string}
     * @memberof Proxy
     */
    httpAddress: string;
}

/**
 * Check if a given object implements the Proxy interface.
 */
export function instanceOfProxy(value: object): boolean {
    if (!('publicIp' in value)) return false;
    if (!('signalStrength' in value)) return false;
    if (!('networkName' in value)) return false;
    if (!('status' in value)) return false;
    if (!('username' in value)) return false;
    if (!('password' in value)) return false;
    if (!('id' in value)) return false;
    if (!('socks5Address' in value)) return false;
    if (!('httpAddress' in value)) return false;
    return true;
}

export function ProxyFromJSON(json: any): Proxy {
    return ProxyFromJSONTyped(json, false);
}

export function ProxyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Proxy {
    if (json == null) {
        return json;
    }
    return {
        
        'publicIp': json['publicIp'],
        'signalStrength': json['signalStrength'],
        'networkName': json['networkName'],
        'status': ProxyStatusFromJSON(json['status']),
        'username': json['username'],
        'password': json['password'],
        'id': json['id'],
        'socks5Address': json['socks5Address'],
        'httpAddress': json['httpAddress'],
    };
}

export function ProxyToJSON(value?: Proxy | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'publicIp': value['publicIp'],
        'signalStrength': value['signalStrength'],
        'networkName': value['networkName'],
        'status': ProxyStatusToJSON(value['status']),
        'username': value['username'],
        'password': value['password'],
        'id': value['id'],
        'socks5Address': value['socks5Address'],
        'httpAddress': value['httpAddress'],
    };
}

