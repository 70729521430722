import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  ProductActionButton,
  ProductDetailsBox,
  ProductPriceTag,
  StyledProductTile
} from './styles';

interface ProductTileProps {
  product: {
    name: string;
    costCents: number;
    subscription: boolean;
    stripePriceId: string;
    id: string;
    freeData: string | null;
  };
  addToBasket: () => void;
  isSubscriptionInBasket: boolean;
}

const ProductTile: React.FC<ProductTileProps> = ({
  product,
  addToBasket,
  isSubscriptionInBasket
}) => {
  const formatPrice = (price: number) => (price / 100).toFixed(2);

  const icon =
    product.subscription && isSubscriptionInBasket ? (
      <AutorenewIcon fontSize="small" />
    ) : (
      <AddShoppingCartIcon fontSize="small" />
    );
  const tooltipTitle =
    product.subscription && isSubscriptionInBasket
      ? 'Change Subscription'
      : 'Add to Basket';

  return (
    <StyledProductTile>
      <ProductDetailsBox>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0.5 }}>
          {product.name}
        </Typography>
        <ProductPriceTag>
          ${formatPrice(product.costCents)} {product.subscription && ' Monthly'}
        </ProductPriceTag>
        {product.freeData && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            + {product.freeData} Starter Data
          </Typography>
        )}
      </ProductDetailsBox>

      <Tooltip title={tooltipTitle} arrow>
        <ProductActionButton onClick={addToBasket}>{icon}</ProductActionButton>
      </Tooltip>
    </StyledProductTile>
  );
};

export default ProductTile;
