import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  Container,
  IconButton,
  Paper,
  Typography
} from '@mui/material';

export const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  maxWidth: 'lg',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    padding: theme.spacing(4)
  }
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  paddingRight: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(4)
  }
}));

export const StyledSideBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    width: '350px',
    marginTop: 0,
    position: 'sticky',
    top: theme.spacing(4)
  }
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  minWidth: '200px',
  maxWidth: '280px',
  margin: '0 auto',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow:
    theme.palette.mode === 'light'
      ? '0px 4px 10px rgba(0, 0, 0, 0.1)'
      : '0px 4px 10px rgba(255, 255, 255, 0.1)',
  backgroundColor: theme.palette.background.paper,
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out'
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)'
}));

export const StyledModalBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '90%',
  width: '100%',
  maxHeight: '100vh',
  overflow: 'auto',
  padding: theme.spacing(2),
  transition: 'height 0.4s ease-in-out, width 0.4s ease-in-out',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2
}));

export const StyledProductTile = styled(Paper)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  borderRadius: '12px',
  width: '100%',
  maxWidth: '500px',
  backgroundColor: theme.palette.background.paper,
  boxShadow:
    theme.palette.mode === 'light'
      ? '0px 2px 8px rgba(0, 0, 0, 0.05)'
      : '0px 2px 8px rgba(255, 255, 255, 0.1)'
}));

export const ProductDetailsBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));

export const ProductPriceTag = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  fontSize: '1.25rem',
  marginTop: theme.spacing(0.5)
}));

export const ProductActionButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  transition: 'background-color 0.2s ease, transform 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'scale(1.1)'
  }
}));

export const StyledCheckoutButton = styled(Button)(({ theme }) => ({
  marginTop: '20px',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow:
      theme.palette.mode === 'light'
        ? '0px 8px 20px rgba(0, 0, 0, 0.15)'
        : '0px 8px 20px rgba(255, 255, 255, 0.2)'
  }
}));
