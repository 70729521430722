import { CircularProgress, TableCell } from '@mui/material';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const CenteredCircularProgress = styled(CircularProgress)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const PaddedTableCell = styled(TableCell)`
  padding: 3rem;
`;

export const GlobalStyle = createGlobalStyle`
    body {
        scrollbar-gutter: stable;
    }

    body::-webkit-scrollbar-track {
        background: transparent;
    }

    body::-webkit-scrollbar-thumb {
        background-color: rgba(136, 136, 136, 0.6);
        border-radius: 10px;
        border: 2px solid transparent;
        background-clip: content-box;
    }

    body::-webkit-scrollbar-thumb:hover {
        background-color: rgba(136, 136, 136, 0.8);
    }

    body::-webkit-scrollbar-corner {
        background: transparent;
    }
`;
