import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  styled
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Plan } from 'src/api/models/Plan';
import accountService from '../../../services/AccountService';
import AccountService from '../../../services/AccountService';

const RootGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const InfoCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'none',
  height: '100%'
}));

const InfoCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(3),
  '& > *:not(:last-child)': {
    marginBottom: theme.spacing(2)
  }
}));

const ActionNavLink = styled(NavLink)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  textDecoration: 'none',
  color: theme.palette.primary.main,
  transition: 'color 0.2s ease',
  '&:hover': {
    color: theme.palette.primary.dark
  },
  '& svg': {
    fontSize: '0.8rem',
    marginLeft: theme.spacing(0.5)
  }
}));

const StatusTiles: React.FC = () => {
  const { account, loading } = accountService.useStore((state) => state);
  const [plan, setUserPlan] = useState<Plan | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const accountData = await accountService.getAccountData();
        if (accountData && accountData.plan) {
          setUserPlan(accountData.plan);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const totalRemaining = useMemo(
    () => (account ? AccountService.getTotalDataRemaining() : 0),
    [account]
  );

  if (loading) return <>Loading...</>;

  return (
    <RootGrid container spacing={2}>
      <Grid item xs={12} md={6}>
        <InfoCard>
          <InfoCardContent>
            {plan ? (
              <>
                <Typography variant="h6">Upgrade Your Plan</Typography>
                <Typography variant="body2" color="text.secondary">
                  Your current plan allows for {plan.allowedProxyCount}{' '}
                  {plan.allowedProxyCount === 1 ? 'proxy' : 'proxies'}.
                  <br />
                  Upgrade your plan to increase the number of proxies you can
                  use.
                </Typography>
                <ActionNavLink to="/management/plans">
                  Upgrade My Plan <ArrowForwardIosIcon />
                </ActionNavLink>
              </>
            ) : (
              <>
                <Typography variant="h6">Purchase a Plan</Typography>
                <Typography variant="body2" color="text.secondary">
                  You currently do not have a subscription. Purchase a plan to
                  start using PuroProxies.
                </Typography>
                <ActionNavLink to="/management/plans">
                  Purchase Plan <ArrowForwardIosIcon />
                </ActionNavLink>
              </>
            )}
          </InfoCardContent>
        </InfoCard>
      </Grid>

      <Grid item xs={12} md={6}>
        <InfoCard>
          <InfoCardContent>
            {totalRemaining > 0 ? (
              <>
                <Typography variant="h6">Need more data?</Typography>
                <Typography variant="body2" color="text.secondary">
                  You have data available.
                  <br /> You can always purchase a one-off data pack whenever
                  you need more.
                </Typography>
                <ActionNavLink to="/management/data-packs">
                  Purchase More Data <ArrowForwardIosIcon />
                </ActionNavLink>
              </>
            ) : (
              <>
                <Typography variant="h6">Purchase a Data Plan</Typography>
                <Typography variant="body2" color="text.secondary">
                  You currently don't have any available data to use.
                </Typography>
                <ActionNavLink to="/management/plans">
                  Purchase More Data <ArrowForwardIosIcon />
                </ActionNavLink>
              </>
            )}
          </InfoCardContent>
        </InfoCard>
      </Grid>
    </RootGrid>
  );
};

export default StatusTiles;
