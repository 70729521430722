import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  Modal,
  Typography,
  Fade,
  Paper,
  useTheme,
  CircularProgress
} from '@mui/material';
import { CloseOutlined, ShoppingCart } from '@mui/icons-material';
import { BasketItem } from './Basket';
import { Product } from 'src/api/models/Product';
import accountService from '../../../services/AccountService';
import checkoutService from '../../../services/CheckoutService';
import { Plan } from 'src/api/models/Plan';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { GlobalStyle } from '../../../styles';

interface CheckoutModalProps {
  open: boolean;
  onClose: () => void;
  basket: Map<string, BasketItem>;
  agreeToTerms: boolean;
  setAgreeToTerms: (agree: boolean) => void;
  addDataPackToBasket: (name: string, costCents: number, id: string) => void;
  products: Product[];
}

const CheckoutModal: React.FC<CheckoutModalProps> = ({
  open,
  onClose,
  basket,
  addDataPackToBasket,
  products
}) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [existingSubscription, setExistingSubscription] = useState<
    Plan | undefined
  >();
  const [loadingPayment, setLoadingPayment] = useState(false);

  useEffect(() => {
    const fetchStripeConfig = async () => {
      const stripe = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLIC_KEY || ''
      );
      setStripePromise(stripe);
    };
    fetchStripeConfig();
  }, []);

  const theme = useTheme();
  const calculateTotal = () => {
    let total = 0;

    basket.forEach((item) => {
      const costCents =
        item.product?.costCents || item.plan?.monthlyCostCents || 0;
      total += costCents * item.quantity;
    });

    return (total / 100).toFixed(2);
  };
  const isAnyItemASubscription = useCallback(() => {
    return Array.from(basket.values()).some((item) => item.plan);
  }, [basket]);

  const hasDataPacks = Array.from(basket.values()).some(
    (item) => item.product && !item.product.subscription
  );
  const handleCheckout = useCallback(async () => {
    if (!isAnyItemASubscription()) {
      const account = await accountService.getAccountData();
      if (!account || !account.plan) {
        return;
      }
    }

    let finalProducts = new Map();

    basket.forEach((item, key) => {
      if (item.product) {
        finalProducts.set(item.product.id.toString(), item.quantity);
      }
    });

    try {
      const session = await checkoutService.createCheckoutSession(
        finalProducts,
        existingSubscription
          ? ''
          : Array.from(basket.values())
              .find((item) => item.plan)
              ?.plan.id.toString()
      );
      setClientSecret(session);
    } catch (error) {
      console.error('Failed to initiate checkout:', error);
    }
  }, [basket, existingSubscription]);

  const handleCloseStripeModal = () => {
    setLoadingPayment(false);
    setClientSecret(null);
  };

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Fade in={open} timeout={400}>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            p: 4,
            borderRadius: 4,
            boxShadow: 24,
            maxWidth: '800px',
            width: '95%',
            mx: 'auto',
            my: '5%',
            position: 'relative',
            maxHeight: '90vh',
            overflowY: 'auto'
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 16, right: 16 }}
          >
            <CloseOutlined />
          </IconButton>

          <Box display="flex" alignItems="center" mb={4}>
            <ShoppingCart fontSize="large" color="primary" sx={{ mr: 1 }} />
            <Typography variant="h4" fontWeight="bold">
              Review Your Basket
            </Typography>
          </Box>

          <Divider sx={{ mb: 3 }} />

          <Paper
            variant="outlined"
            sx={{
              mb: 4,
              p: 2,
              maxHeight: '50vh',
              overflowY: 'auto',
              borderRadius: 3,
              boxShadow: 2
            }}
          >
            <List sx={{ p: 0 }}>
              {Array.from(basket.entries()).map(([id, item]) => (
                <ListItem
                  key={id}
                  sx={{
                    py: 2,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    '&:last-child': { borderBottom: 'none' }
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box>
                      <Typography variant="h6" fontWeight="bold">
                        {item.product?.name || item.plan?.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Quantity: {item.quantity}
                      </Typography>
                      {item.plan && (
                        <Typography variant="body2" color="text.secondary">
                          Monthly Cost: $
                          {(item.plan.monthlyCostCents / 100).toFixed(2)}
                        </Typography>
                      )}
                    </Box>
                    <Typography variant="h6" color="primary">
                      $
                      {(
                        ((item.product?.costCents ||
                          item.plan?.monthlyCostCents ||
                          0) *
                          item.quantity) /
                        100
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </ListItem>
              ))}
            </List>

            {basket.size === 0 && (
              <Box textAlign="center" py={4}>
                <Typography variant="body1" color="text.secondary">
                  Your basket is empty. Add some items to proceed with checkout.
                </Typography>
              </Box>
            )}
          </Paper>

          {/*{!hasDataPacks && basket.size > 0 && (*/}
          {/*  <Box*/}
          {/*    sx={{*/}
          {/*      backgroundColor:*/}
          {/*        theme.palette.mode === 'dark' ? '#424242' : '#f5f5f5',*/}
          {/*      color: theme.palette.text.primary,*/}
          {/*      p: 3,*/}
          {/*      mb: 4,*/}
          {/*      borderRadius: 2,*/}
          {/*      borderLeft: `6px solid ${theme.palette.warning.main}`,*/}
          {/*      boxShadow: 1*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <Box display="flex" alignItems="center" mb={2}>*/}
          {/*      <WarningAmberOutlined*/}
          {/*        sx={{*/}
          {/*          fontSize: 32,*/}
          {/*          mr: 2,*/}
          {/*          color: theme.palette.warning.main*/}
          {/*        }}*/}
          {/*      />*/}
          {/*      <Typography variant="h6" fontWeight="bold">*/}
          {/*        Missing Data Packs*/}
          {/*      </Typography>*/}
          {/*    </Box>*/}

          {/*    <Typography variant="body1" sx={{ mb: 2 }}>*/}
          {/*      You haven't added any data packs to your basket. Ensure you have*/}
          {/*      enough data to enjoy uninterrupted service. Would you like to*/}
          {/*      add a data pack now?*/}
          {/*    </Typography>*/}
          {/*    <Box display="flex" flexWrap="wrap" gap={1}>*/}
          {/*      {products*/}
          {/*        .filter(*/}
          {/*          (product) =>*/}
          {/*            product.productType === 'DataPack' && product.active*/}
          {/*        )*/}
          {/*        .map((product) => (*/}
          {/*          <Chip*/}
          {/*            key={product.id}*/}
          {/*            icon={*/}
          {/*              <AddCircleOutline*/}
          {/*                sx={{ color: theme.palette.primary.main }}*/}
          {/*              />*/}
          {/*            }*/}
          {/*            label={`${product.name} - $${(product.costCents / 100).toFixed(2)}`}*/}
          {/*            clickable*/}
          {/*            color="primary"*/}
          {/*            onClick={() =>*/}
          {/*              addDataPackToBasket(*/}
          {/*                product.name,*/}
          {/*                product.costCents,*/}
          {/*                product.id*/}
          {/*              )*/}
          {/*            }*/}
          {/*            sx={{*/}
          {/*              fontSize: '1rem',*/}
          {/*              py: 1,*/}
          {/*              px: 2,*/}
          {/*              borderRadius: 2,*/}
          {/*              backgroundColor:*/}
          {/*                theme.palette.mode === 'light'*/}
          {/*                  ? theme.palette.grey[300]*/}
          {/*                  : theme.palette.grey[600],*/}
          {/*              color: theme.palette.text.primary,*/}
          {/*              '& .MuiChip-icon': {*/}
          {/*                color: theme.palette.primary.main*/}
          {/*              },*/}
          {/*              '&:hover': {*/}
          {/*                backgroundColor:*/}
          {/*                  theme.palette.mode === 'light'*/}
          {/*                    ? theme.palette.grey[400]*/}
          {/*                    : theme.palette.grey[700]*/}
          {/*              }*/}
          {/*            }}*/}
          {/*          />*/}
          {/*        ))}*/}
          {/*    </Box>*/}
          {/*  </Box>*/}
          {/*)}*/}

          <Divider sx={{ my: 4 }} />

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
          >
            <Typography variant="h5" fontWeight="bold">
              Total Amount:
            </Typography>
            <Typography variant="h5" fontWeight="bold" color="primary">
              ${calculateTotal()} (excl. tax)
            </Typography>
          </Box>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleCheckout}
            disabled={basket.size === 0 || loadingPayment}
            sx={{
              py: 1.5,
              fontSize: '1.2rem',
              fontWeight: 'bold',
              borderRadius: 2
            }}
          >
            {loadingPayment ? 'Awaiting Payment...' : 'Proceed to Checkout'}
          </Button>

          <Modal
            open={!!clientSecret}
            onClose={handleCloseStripeModal}
            aria-labelledby="stripe-checkout-modal"
            aria-describedby="stripe-checkout-modal-description"
            closeAfterTransition
            BackdropProps={{
              timeout: 500,
              style: { backdropFilter: 'blur(3px)' }
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                width: '100vw',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                position: 'relative'
              }}
            >
              <IconButton
                onClick={handleCloseStripeModal}
                sx={{
                  position: 'absolute',
                  top: 16,
                  right: 16,
                  color: 'white'
                }}
              >
                <CloseOutlined />
              </IconButton>
              {!clientSecret ? (
                <CircularProgress color="inherit" />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: clientSecret ? '95%' : 'auto',
                    width: clientSecret ? '100%' : 'auto',
                    maxHeight: '100vh',
                    overflow: clientSecret ? 'auto' : 'hidden',
                    padding: 2,
                    transition:
                      'height 0.5s ease-in-out, width 0.5s ease-in-out'
                  }}
                >
                  <Box
                    sx={{ width: '100%', height: '100%', overflowY: 'auto' }}
                  >
                    <EmbeddedCheckoutProvider
                      stripe={stripePromise}
                      options={{ clientSecret }}
                    >
                      <GlobalStyle />
                      <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                  </Box>
                </Box>
              )}
            </Box>
          </Modal>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CheckoutModal;
