/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductIdQuantity } from './ProductIdQuantity';
import {
    ProductIdQuantityFromJSON,
    ProductIdQuantityFromJSONTyped,
    ProductIdQuantityToJSON,
} from './ProductIdQuantity';

/**
 * 
 * @export
 * @interface CheckoutRequest
 */
export interface CheckoutRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckoutRequest
     */
    planId?: string;
    /**
     * 
     * @type {Array<ProductIdQuantity>}
     * @memberof CheckoutRequest
     */
    productIdQuantites?: Array<ProductIdQuantity>;
}

/**
 * Check if a given object implements the CheckoutRequest interface.
 */
export function instanceOfCheckoutRequest(value: object): boolean {
    return true;
}

export function CheckoutRequestFromJSON(json: any): CheckoutRequest {
    return CheckoutRequestFromJSONTyped(json, false);
}

export function CheckoutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckoutRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'planId': json['planId'] == null ? undefined : json['planId'],
        'productIdQuantites': json['productIdQuantites'] == null ? undefined : ((json['productIdQuantites'] as Array<any>).map(ProductIdQuantityFromJSON)),
    };
}

export function CheckoutRequestToJSON(value?: CheckoutRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'planId': value['planId'],
        'productIdQuantites': value['productIdQuantites'] == null ? undefined : ((value['productIdQuantites'] as Array<any>).map(ProductIdQuantityToJSON)),
    };
}

