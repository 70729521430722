/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountRole } from './AccountRole';
import {
    AccountRoleFromJSON,
    AccountRoleFromJSONTyped,
    AccountRoleToJSON,
} from './AccountRole';
import type { DataPack } from './DataPack';
import {
    DataPackFromJSON,
    DataPackFromJSONTyped,
    DataPackToJSON,
} from './DataPack';
import type { Plan } from './Plan';
import {
    PlanFromJSON,
    PlanFromJSONTyped,
    PlanToJSON,
} from './Plan';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    email: string;
    /**
     * 
     * @type {Plan}
     * @memberof Account
     */
    plan?: Plan;
    /**
     * 
     * @type {Array<DataPack>}
     * @memberof Account
     */
    dataPacks: Array<DataPack>;
    /**
     * 
     * @type {AccountRole}
     * @memberof Account
     */
    role: AccountRole;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    referrer?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    affiliateCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    apiKey: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    proxyPassword?: string;
}

/**
 * Check if a given object implements the Account interface.
 */
export function instanceOfAccount(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('email' in value)) return false;
    if (!('dataPacks' in value)) return false;
    if (!('role' in value)) return false;
    if (!('apiKey' in value)) return false;
    return true;
}

export function AccountFromJSON(json: any): Account {
    return AccountFromJSONTyped(json, false);
}

export function AccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Account {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'plan': json['plan'] == null ? undefined : PlanFromJSON(json['plan']),
        'dataPacks': ((json['dataPacks'] as Array<any>).map(DataPackFromJSON)),
        'role': AccountRoleFromJSON(json['role']),
        'referrer': json['referrer'] == null ? undefined : json['referrer'],
        'affiliateCode': json['affiliateCode'] == null ? undefined : json['affiliateCode'],
        'apiKey': json['apiKey'],
        'proxyPassword': json['proxyPassword'] == null ? undefined : json['proxyPassword'],
    };
}

export function AccountToJSON(value?: Account | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email': value['email'],
        'plan': PlanToJSON(value['plan']),
        'dataPacks': ((value['dataPacks'] as Array<any>).map(DataPackToJSON)),
        'role': AccountRoleToJSON(value['role']),
        'referrer': value['referrer'],
        'affiliateCode': value['affiliateCode'],
        'apiKey': value['apiKey'],
        'proxyPassword': value['proxyPassword'],
    };
}

